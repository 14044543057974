import { debounce } from '../utils/debouce';

const Carousel = el => {
    let swiper;

    const paginationEl = el.querySelector('.swiper-pagination');

    const createSwiper = () => {
        swiper = new Swiper(el, {
            loop: true,
            centeredSlides: true,
            // mousewheel: true,
            pagination: {
                el: paginationEl,
                clickable: true,
            },
            slidesPerView: "auto",
            slideToClickedSlide: true,
            spaceBetween: 0,
        });
    }

    window.addEventListener('resize', debounce(() => {
        if (swiper) {
            swiper.destroy();
            swiper = undefined;
            createSwiper();
        }
    }, 50));

    createSwiper();

    swiper.slides.forEach(slide => {
        slide.addEventListener('click', e => {
            if (slide.classList.contains('swiper-slide-active')) swiper.slideNext();
        });
    })
}

export default Carousel;