const SideNav = el => {
  const anchors = [...el.querySelectorAll('a')];
  const items = [...el.querySelectorAll('.sidenav__list-item--active')];

  const markerEl = el.querySelector('.sidenav__list-marker');
  const markerHeight = markerEl.getBoundingClientRect().height;

  let sideNavCoords, sectionCoords;

  const deselect = () => {
    anchors.forEach(el => el.parentElement.classList.remove('sidenav__list-item--active'))
  };

  const select = anchor => {
    deselect();
    if (!anchor) return;
    const listItem = anchor.parentElement;
    const rect = listItem.getBoundingClientRect();
    listItem.classList.add('sidenav__list-item--active');
    positionMarker(rect);
    el.setAttribute('data-current', anchor.href.split('#').pop());
  };

  const positionMarker = coords => {
    let y = (coords.y - sideNavCoords.y) + (coords.height / 2) - markerHeight/2;
    // console.log()
    // y += coords.height / 2 - markerEl.getBoundingClientRect().height;
    gsap.to(markerEl, {y, duration: 1})
  }

  const handleClick = e => {
    select(e.currentTarget);
  }

  const handleResize = e => {
    sideNavCoords = el.getBoundingClientRect();
    sectionCoords = anchors
      .map(anchor => anchor.href.split('#').pop())
      .map(id => document.getElementById(id).getBoundingClientRect().top + window.scrollY || window.pageYOffset);
    handleScroll();
  }

  const handleScroll = e => {
    let index = 0;
    sectionCoords.forEach((y, sectionIndex) => {
      const cY = window.scrollY || window.pageYOffset;
      if(sectionCoords[sectionIndex + 1]) {
        // y                                  | top of section
        // sectionCoords[sectionIndex + 1]    | bottom of next section
      }
      if (sectionIndex !== sectionCoords.length -1) {
        if (cY >= y && cY < sectionCoords[sectionIndex + 1] + window.innerHeight) index = sectionIndex
      } else if (cY >= y - window.innerHeight/2) index = sectionIndex;
    });
    const current = items.indexOf(items.find(item => item.classList.contains('sidenav__list-item--active')))
    if(index !== current) {
      select(anchors[index]);
    }
  }

  anchors.forEach(a => {
    a.addEventListener('click', handleClick, false);
  });

  handleResize();

  window.addEventListener('resize', handleResize);

  window.addEventListener('load', handleResize);

  window.addEventListener('scroll', handleScroll);

  if (location.hash) {
    const current = anchors.find(anchor => anchor.href === location.href);
    select(current);
  }

}

export default SideNav;