const InputNumber = el => {

    const input = el.querySelector('input');
    const min = input.getAttribute('min');
    const max = input.getAttribute('max');

    const createButton = direction => {
        const button = document.createElement('button');
        button.classList.add('input__step')
        button.classList.add(direction);
        return button;
    }

    const up = createButton('up');
    const down = createButton('down');

    const inc = e => {
        const value = parseFloat(input.value) + 1;
        if(max && value <= parseFloat(max)) {
            input.value = value;
        }
    }

    const dec = e => {
        const value = parseFloat(input.value) - 1;
        if (min && value >= parseFloat(min)) {
            input.value = value;
        }
    }

    el.appendChild(up);
    el.appendChild(down);

    up.addEventListener('click', inc, false)
    down.addEventListener('click', dec, false)
}

export default InputNumber;