import inView from "../utils/inView"

const Video = el => {

  let status = undefined;
  const handleEvent = e => {
    status = e.type;
  }

  el.addEventListener('play', handleEvent);
  el.addEventListener('pause', handleEvent);
  el.addEventListener('ended', handleEvent);

  if (window.subscribeToTick) {
    window.subscribeToTick(() => {
      if (status === 'play' && !inView(el)) el.pause();
      if (inView(el) && status !== 'play' && el.autoplay) {
        let promise = el.play();
        if (promise !== undefined) {
          promise.then(_ => {

          }).catch(err => {

          });
        }
      }
    })
  }
}

export default Video;