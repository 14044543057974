export const policies = [
  "5ed963b3b9c5760ede28495ad0ed4fe2f4b1cfc3dd3335e452d809bd",
  "6d69e1969581ed7349cde32940769103020d24462aecba19b72652c7",
  "7ddf86d6b67999008464178000b1f2a8635008910447ba7c78e8c776",
  "1803140bac355b381f110301938c45e2af5256dadb6ee96282241a8c",
  "0cf3b978ca9d5d3204cfe21ba855b4bb0543331b983ffef81fb4a876",
  "4525ed592fc710baa892e91eeb2c3442629b8e18c1e44c4f73a0f365",
  "c2ced4f1c2125d1c381e7eb47876a8485e2ba50c4722782aa39d6935",
  "3c0f21886ab32fe6a8c6751898c58a83409d125e086546d2d852c6af",
  "6e8c1104e426462655f814574e3d7bb0901cea211fa6a327b4f9c57e",
  "7be8b5c60e913079bc1ccf58247dbaf5433fcb70d0abc89a7dd333d4",
  "2d5c8da81b06d70694a39349cd5eed12c287f725d88fe5428d17efb5",
  "7a744f69fd5370353e8510cf04ce609bca49a7db6f0ead184fc65406",
  "9046c4fea7a38f05655c17ed01082ea302521ecb8fdf130cf443a7d6",
  "7ceabb4221201121adbd11349c5a13687faeb38107eb4917d3287067",
  "8af3068889a7cd26eccc1db74f9be10bd4987008669fe6acbec11467",
  "1bb7a7e3677548cfbba241259bedf08b8193dacdb7b0f28274a86f35",
  "5ac8639d63cc7c6ee78a3cf78942f14f8360daf9ae836752aba89e2f",
  "c5106bb7bad3b186563122fa936970e1532889707819ee28df87e8e8",
  "4fa578ed70b8f69e0736a0918628e85fe4c36954837948e743680c68",
  "a8ffc4f39f236f99f6a12655230a975c935457172a900976eb2b3743",
  "a780d38cf3180361b1dff604e17ee0e8bff0626a9e743bf341c26268",
  "499853e6a5af925f2655f8cf8e6883d3ae7a3a184ac556034deabdc5",
  "c71279e0954dfc999a8f5cf7c703a0b31c52d26518012eebf3a9fd17",
  "c4bfa78ebb256a2e869d5b3c0d5d24754f5875e5273b37a4e369bda6",
  "5d880807f558fc441a05024765120e769456ca79c828eba9ce7c5fbf",
  "95ef16ded5edd5a2f61ad1507348785c7dc9df0f1ac38cac32f45922",
  "646edf22356a2fe9c88ac4de4e1b77e4bb2616fcc46753fdaad79c92",
  "5f5739ee1c73dcc7a27a8ef538e0ac72f3e3729fd60a434eaad48507",
  "09a05cce8bc7c8cb46a78c2b96bc2e10efdb214f17e2793c6b3bd5b0",
  "926026dc04e14a2d380cf2e4decd2f795a75f5622b5fe5e1bee4ce8b",
  "f27a17e3a693d6994abf249bcd5cbf4599ea433eb6c66300dfaa5209",
  "561f30306344270340cf4756c04b7dee6ee14b975972d40618344fce",
  "8ca0287f49b3886e7563939e0870beccc48c282c65f3bd679d807cb8",
  "49450fe5ce314aa6ef5d4c947d69d4af8e298a005114718a7cc1f784",
  "259b6886b058ce34d1c1c116b1183b751d63eebc14fbbc36e918c951",
  "16aa5486dab6527c4697387736ae449411c03dcd20a3950453e6779c",
  "fd0c2767ce7caba40121ce937fdd72e4770555293e46c83e5e03fac9",
  "c418822d623055c5378134493043300684939ace5d0b12f3695eab37",
  "fd8fb9de7c08af1dab160004563d4b2b9a36203888859632c5f27b12",
  "5f9cdb60b6e7009e4a8fcf7f0ba0009eeea875313fc488c900266518",
  "1655bca3782f67aa71f0d3e7306225edd47fe41739b69c86a58b8900",
  "b6518e07e67f6300c75b547c16f08d9d5c6276c10e906ce18d0d747f",
  "c32cfc3b0352f8be5c614d18502f7f806e609d423c68d2a5c1dbcff9",
  "ded141fb085c0cf299af29df2e6a1b647c64caf01cb72fa4f1053231",
  "95fe8c9900d7f3273f03a3d4c160d7aaf1d6e917d11c99001250fa5d",
  "3d63e2d93f8c1c94e1b38a899f476296718425805c792439e7c92a4d",
  "ea12daa6839715fc56d53d704f9327a1f8620d6eae322c1f4008ec92",
  "e1e18480e30df3381b75ed81f74de34ddf12c81e1551ce9a99fdc72b",
  "1ca85d4700d27dbc224eebf888251fbeb1e6faea1d277710ddf7fe8d",
  "4abaa6f6bafa199b30cb93f2f48229feca89c4d8e30c85c8c90b8ad9",
  "a38ea64580f3d5c8a9262d94f7aaedac9e171083244055ea5a9815e7",
  "96a822e140c8a3c274b0b19325a9c2a37fa9bcbc68b0ac7e423d11bc",
  "b6c2369ab4f5155f868b6dc885772829bf292ef89e0c707d19f1a312",
  "3485b5badbc251236ee62f4fbfb2a806f88655c2fdd3d41344d42a15",
  "dac41c7f27ac00de879736a3252eb2cc0b770c84f97c777b534fd4a4",
  "1ddad555066d87d93be06d1c5999f4b7b52723d3acfa1545408a653d",
  "bc9dacb919621fa67e77e5d2d17c5811fafe16af2c691e3076c0182d",
  "26bdd70c3f9360cbefee7e4ce7b8cf0bf67155446d38a99693308a12",
  "593d06f6e36fa03fe8e55e7310bdc31b01ca70fc3882f8fd134f1f2a ",
  "d0046e0eafbc63b1b47c9410196ca5cd89ba5caae7d900320d254a13",
  "148b29dce34d2d9f6baf3b0aa53db4ee64976a2f82c91175a0d3f5c4 ",
  "3906dec80c892ed1d27a2fbc319d9a38921e92bab7f2f43e80c447f2 ",
  "fac6ec8500bf6d884802c76eaed2d383094a17e640ae061af5d3b1fa",
  "1b6ecfe62b0867a28c116233974861a0e2b34f3c45da48cac7ad10ac",
  "516ea409dac6b8b5ba6740dcb476b9243a3da0f6d7e1a68bef6b54a4",
  "10bce6401940929888f0d514bc59fcecd5a4cf36d152f58b11365e5b",
  "349b99288181cb7ba1ca65f5e188dd9b9a9dc1ee1694fda7c4e61772",
  "de421e04b847b42b7722f0b898cb7f13dc29ab1d4d9ddecf5675ca0c",
  "6a8188c8b774894684275adce0536921d4fa7ee8bdc3977b9f62830e",
  "56b9872d5260e7b6b2135dd38453e9820cd33f893689e26986d8d011",
  "f11ddbdadbc7b78b7110293281844ba8acac918f1ed5767539d7d52e",
  "05ec84b083bd9708ea1a27e431f6dd99a3b4f1f4f8c01318a8e3520e",
  "5c42b0f4160f5d3369b015224b615d61bd1660060b61196c3beee096",
  "691d5cd8edee42c504f63a8801a242fe75303543dedd8df981ae538a",
  "9a1ce59bb4c8e2bca49c339d60142c3a43d4d74d767cf6c28b3c111d",
  "fb1147e457e39f059d7938ede72b60c341510583f5766ba545235298",
  "58523c5b2cf8d682a1c087f316872e1523a61c73c92f5c25c9cc4865",
  "90dfc2d23aa391e6118bbd13dc13c8a207108dc3f22346a03a5d9208",
  "a832f0ba93582046bde3359d3237f9fb5cfd88c435d1ad71a9665cdf",
  "82cd2703b92306ecf70cc81b099bff67ec884f4349ffbb8d31fc87ff",
  "6d94df95819251df4d66921a244ab13dcb3f21f9da1ce6530e80caef",
  "f6ab7b7c42b50540bfb4354d7622e50a651425246d27292be667bf4e",
  "5380c1fe80547a9317dbd4f45cb92d57e320f4bb93a467a7c98e0892",
  "c53df5abd223e263ee82538ed6682ae5669b033068cbae6eb1b21059",
  "3dc84447bd66e1ab45fb9a9d63d2db1603d848bb40c3d22e77635841",
  "74428b1442547159a05c9bb3d7ff77ea6a6b0f55fddc8ec56cb6ca80",
  "0a1edba9b8221b65291757308abc4f85b1bf8a6c19ef0b8e62f1d90b",
  "321717eeb2a65153aa32185c600936d0a6f7e5d211caa5c94c7f97fe",
  "c2eea73b97a11c6c608a3dec1dcdf8078edb3086ccc60256f31a95c8",
  "2cbd7f851cca03779b87bb0dc6ebfa80763ab26afe8c6f966dd04d49",
  "867f4f6bdc2e832f662faa2cd6da14e425568af9f4dabab5e630c17d",
  "e3ed0f13ca728501c46441ff7730ce8d4f0de437f435f02eb36eb4d4",
  "00175dac7b8e4b9d2e2bd0bf42c267162f6ffa56d8271c5884d636a6",
  "3906dec80c892ed1d27a2fbc319d9a38921e92bab7f2f43e80c447f2",
  "b4f6f8162215ed3f4f495665065c915668d0e5e9bec94fd28d9a1942"
];

export const cardolphinPolicy = [
  "b5fb333ad44e4853484cef178f543c35564490534aafcdf919fa4687"
];

export const partnerProjects = [
  "3bcc312ebe7cd9281ab3e3d641bf70f207012e539b0e6e7c3f1560d7",
  "4e5f3e165b93c99d922111d9818c03f33e8da8a9f6ae1465c7869a21",
  "11ff0e0d9ad037d18e3ed575cd35a0513b8473f83008124db89f1d8f",
  "75331768772e5eba2cb00454e6a3be3bbac991cd43b2dcc738f4adac",
  "efa591af8a72f86b7020d9b2cb09a5b93468d14f58146f97c680fd25",
  "c859e9d7e71b8f90bdc1e453fd1b9adbc5e6163898fb574543cb5be8",
  "d0112837f8f856b2ca14f69b375bc394e73d146fdadcc993bb993779",
  "d6e3ae03a94c9748189f41e9d180f91e971f18909ea52559c977c259",
  "2feb543256ec14b0ccde8892ccbecbf5fa0e6f36f19f6cdc63930c2c",
  "f33617a1fb3a5c37461fb88e3d510b6aed0feb6ee8bb0e162475f4f2",
  "637bae5845e801779651d997bedc2dad53ddbe0d5f940b4ff723d769",
  "c117f33edeee4b531dfdb85ead5753433c9dbd875629bc971013ffac",
  "5c80d8420b415e6f277d830e780190f288993019108bebecf5ccf9e1",
  "6ce2d3c06e323d522d0d1c67b4ebae39d62549acc633733e357d6027",
  "1079cdd8bf9c146f7703aa19f26c6726134e2bcfce9fe0572d7c5864",
  "3f00d83452b4ead45cf5e0ca811fe8da561dfc45a5e414c88c4d8759",
  "b65ce524203b7a7d48b55ff037c847c5ec8c185cd3bdb7abad0a02d4",
  "2b43a53d30347c1983d632e0437e2dec5a5c00ee3007627f1f0b1d30",
  "e9e51c2cef2cb734f5ac7a0ceb84e0b8805b93f15f60f054d0bc04cc",
  "b5fe4b252a54d6c39689c740e9f5b18355b9b983da2295cee6906420",
  "6f87aa2ea552ecd24732d62831916ece54c52348417398085358cbed",
  "c7fe3a3c3443a2a3d453efb7df0c276712bb22f8470f12965f5cae42",
  "d0b4c7811012fc5e9860c2fe374265f4e465ff99586ed7352fa9a866",
  "d6fe6efa7788cb70e57a91891605e3694352cabb4837e870610300e9",
  "8903555ad05ed1794f26240d44137717d0c8049e9133266222c4186a",
  "a4c45615825acae7c4937ee4d45d2ff9a29328084e2dc34bf4af37b2",
  "a23836ef3b4d0ad3ed1c28bd30e754e208ae7ea0a23e809354d67e0d",
  "2058eda85ddd54815bf4483aeb7a294c54ecf83b89d1d642f0481a58",
  "4b0a8d5ff1f8ef6d0a651357e9cb001e07b8cd89763bb19d6dc2b4c9",
  "590048dda6a624c887c0466b178c16fff3dedc2aac5651a9e0fc41b6",
  "46f96dbed1e14d3fe1ca8ed08e7db2f848b50769b51ebf6547728ba2",
  "8feebc6c314ce6394c50bb32a94459f71c22bfb31fefc1f1154bbbc4",
  "8867d212a7948a5f6e7c36a72a62093358981421b979a5f4e3eb5a30",
  "f97bb2ae7b056a17a63847fbe5032148353d30c980f5467f51f19637",
  "c9c4d9e7fdec835f0cb95b15348509872322a888a9fac4f64dfef0ec",
  "874bffc030b6a63e1711903bc47552eb11e55da54a5798a9b22f07c5",
  "53d77277dc1a921da533f05b8712fcca5ca7f085558e9ea4e33979a9",
  "ca25fcbaed9ec8a2cc3e33773f0df5825105781f969f6ab4466e37a1",
  "b0fd70c54645a926e7a1e651869f93f1354245712c97128222805909",
  "c001c001f524dccec5d2d48341837305f1f161d95fa1c6d999a205c2",
  "2aec93fa65aaedaf2fc0aa46c3ace89c0c8e091ed5f39b8f8127e664",
  "c001facec0dc28c1a286be35b9ddb8b47fd9d0da1564e7db81e2fbe7",
  "063bbb14b22e39aedf7beba84d7ce9ea771a6f19d9ad02f70d6f3eaa",
  "b001076b34a87e7d48ec46703a6f50f93289582ad9bdbeff7f1e3295",
  "cf24a733036e84b44ca83b8412181278418d1ea198024df1861bd34e",
  "44ab454ca90090dfe732622ffaf71359f991e8bf0a8e408831faeab7",
  "0970b6ce866c26145c6dd0cff6aa18a7c3e3eae45e19feecd97c1b0f",
  "729e0d019be399a241197a31c6777d6983e89ad6970e9c5ab5c5ff4d",
  "6ec5ac754f9f9a76716d6f9133d3c23f0a5ce167a0343511125d009a",
  "9a55c97b7bf4d7b8b28fcf6f4a71242c4cf2c0fa224db212e496caf5",
  "1e179fa84cb7bbf6061fbc8ee404bbbb04a297dfb46382a4f7e43dff",
  "571172ef85da56912bfe4d198e7b54d1404b0da5301bcdd2014d1d64",
  "3ca291585bbbd1deafab0495f8a7fda4753fbeae96c550492bcfd760",
  "6d0a27c58ad5b6cebc72c83b4970229eb23875837614c318c1a998dd",
  "6eefdae9f6a7214b53c8d99769955f11dd5d9b12f731eeb0ff6b12d9",
  "35d87982b55ba5c43eea9e018feb2031ff228b48f5867c2894e8e402",
  "020b9ae360591b0670a883457189add3f7de4f6f8ed884be036a5102",
  "e2712081e16ebbfffd38c9f6f3510907256472527a8b77254191c59b",
  "592a4dbfc2e900a2ebb410fae096008a1d4a9b5bec7166a78e943565",
  "b1fa21bfd6a1ce7936e537b69f188e8d910ddb27f313655b0c192a90",
  "529f68d729aae733e01a1dfabcfb505144728a7432a54bff3d20997f",
  "95fe2fb623ea7a2f02c8ba96ba9a69fdaea88624b6478f52d0c0aa4b",
  "f85be1a54efa2607de78a0f8855f9d73c9dd79bfa116f3767c015098",
  "ca28861674c6d932b32a22658e411da7e3fac78f1e1a282c306ef9df",
  "e847a14c07f4ba94e18af4fac2fa3d462c1e95c26d77315603066e08",
  "4da6fc5702467eccfd7080ad4889d37b73173bee9701dfe04f22ea64",
  "f9a098a6dbc3979a97ca7a8f41c2588e6a61aa59938fec991e542c52",
  "ec34188bf665a86d2f8df68fd09f0dc9cf4846d61f1283ff76691e2e",
  "3eacbbed674414d71b0dd6f2393451df4066b931572b58a485211d32",
  "38f25e7bfba4b4c65de7b55d68475dcc6f02a376ec53c796ab5723b8",
  "1761ee45f06a66d1915b4b439f82faa337b7dbfdda6640e780e9315a",
  "dac743919d2f366f7e4be411ec56b188733f3b6f070e50b240ac8277",
  "ff1463bdfaf9268cafba9918a4b389723a90a21b3a4068d36b2fe405",
  "93e06be903136659581e6aaa5e54a3500c56f03c434e7d9f84a18239",
  "1a6d92b8b5a08ea101274853632e84819a8ab02d15461fdf98dbb96f",
  "eaae5adf3c46e0868e88d2456fb68168136cb72eb79098625b435dd0",
  "f9c7a0cb60e2bd7c2bcd7041460dc19f8767623a735994e1d2f54186",
  "f1dd8861a9b92fb42c54c3d11486a7ae60a48c3aaa87b717d586a667",
  "6787a47e9f73efe4002d763337140da27afa8eb9a39413d2c39d4286",
  "5ec2e9813fa385d9333d18186d8257d1b3ebea97bdec2dad74026d8d",
  "341fe1a53fb486918131d1be7cd97c1fb459ff3bc938ac8eaf4a7e5f"
]

