import slugify from 'slugify';
import throttle from '../utils/throttle';

const LIMIT = 8;


const NFTList = el => {
  const url = 'https://pool.pm/wallet/$rugsnacks';
  const imgBaseUrl = 'https://infura-ipfs.io/ipfs/'
  const arrayUniqueByKey = (key, array) => ([...new Map(array.map(item =>
    [item[key], item])).values()]);
  const imgs = [...el.querySelectorAll('.treasure-chest__list-item-image')];
  const errors = [];
  let loaded = 0;

  const list = el;
  const items = [...el.children];

  const handleResize = () => {

    // console.log('handleResize');

    // console.log(window.innerWidth)

    if (window.innerWidth <= 576) {
      // limit to x amount of nfts to show
      const listBox = list.getBoundingClientRect();
      const itemBox = items[LIMIT - 1].getBoundingClientRect();

      const maxHeight = (itemBox.height + itemBox.y) - listBox.y
      console.log(maxHeight)

      list.style.maxHeight = `${maxHeight}px`;
    } else {
      // show all
      list.style.maxHeight = '';
    }
  }

  const handleErrors = () => {
    fetch(url)
      .then(response => response.json())
      .then(data => {
        errors.forEach(img=> {

          const filename = slugify(img.dataset.src, { lower: true });
          const policyId = img.dataset.policyId;
          const token = data.tokens.find(token => token.policy === policyId);

          if (token) {
              console.log(token);
              let image = token.metadata.image;

              if (typeof image === 'object') image = image.join('');
              image = image.replace('/ipfs/ipfs', '/ipfs')

              console.log(filename)
              console.log(image)

              fetch(`https://localhost:8080/save-nft?name=${filename}&url=${image}`,
                {
                  mode: 'no-cors'
                })
                .then(response => response.json())
                .then(() => { })
          }
        });
      });

    window.addEventListener('resize', throttle(handleResize, 100));
    handleResize();
  }

  imgs.forEach((img) => {
    img.addEventListener('load', e => {
      loaded++;
      if (loaded + errors.length === imgs.length - 1) handleErrors();
    });
    img.addEventListener('error', e => {
      errors.push(img);
      if (loaded + errors.length === imgs.length - 1) handleErrors();
    });
    img.src = img.dataset.src
  });



  /*
  fetch(url)
    .then(response => response.json())
    .then(data => {
      imgs.forEach((img, index) => {
        const filename = slugify(img.dataset.name, { lower: true });
        const policyId = img.dataset.policyId;
        const token = data.tokens.find(token => token.policy === policyId);

        if (token) {
          img.addEventListener('error', e => {
            console.log(token);
            let image = token.metadata.image;

            if (typeof image === 'object') image = image.join('');
            image = image.replace('/ipfs/ipfs', '/ipfs')

            console.log(filename)
            console.log(image)

            fetch(`https://localhost:8080/save-nft?name=${filename}&url=${image}`,
              {
                mode: 'no-cors'
              })
              .then(response => response.json())
              .then(() => { })
          });
        }
      })
    });
    */
}

export default NFTList;