import lottie from 'lottie-web';
import { getPixel } from "../utils/perlinNoise";

const Trash = el => {

    const perlin = window.perlin;
    const canvas = document.createElement('canvas');
    const bubbles = el.querySelector('.bubbles');
    canvas.width = perlin.canvas.width
    canvas.height = perlin.canvas.height
    canvas.getContext('2d').putImageData(perlin.imageData, 0, 0);

    const init = () => {
        const rect = img.getBoundingClientRect();

        const opts = {
            shift: el.dataset.shift !== 'false',
            scaleX: parseFloat(el.dataset.scaleX) || 10,
            scaleY: parseFloat(el.dataset.scaleY) || 4,
            opacity: parseFloat(el.dataset.opacity) || 0.6
        }

        const ptX = {
            x: Math.floor(Math.random() * perlin.canvas.width),
            y: Math.floor(Math.random() * perlin.canvas.height),
        }

        const ptY = {
            x: Math.floor(Math.random() * perlin.canvas.width),
            y: Math.floor(Math.random() * perlin.canvas.height),
        }

        const app = new PIXI.Application({
            width: rect.width,
            height: rect.height,
            backgroundAlpha: 0
        });

        el.appendChild(app.view);

        const container = new PIXI.Container();
        app.stage.addChild(container);

        const trashSprite = PIXI.Sprite.from(img.src);
        trashSprite.width = rect.width;
        trashSprite.height = rect.height;
        trashSprite.alpha = opts.opacity;
        container.addChild(trashSprite);

        const displacementSprite = PIXI.Sprite.from(canvas);
        displacementSprite.blendMode = PIXI.BLEND_MODES.OVERLAY;
        displacementSprite.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT;

        app.stage.addChild(displacementSprite);

        const displacementFilter = new PIXI.filters.DisplacementFilter(displacementSprite);
        displacementFilter.scale.x = opts.scaleX;
        displacementFilter.scale.y = opts.scaleY;

        trashSprite.filters = [displacementFilter];

        img.style.display = 'none';
        const render = () => {
            displacementSprite.texture.baseTexture.resource.source.getContext('2d').putImageData(perlin.imageData, 0, 0)
            displacementSprite.texture.baseTexture.resource.update();
            displacementSprite.texture.update();
            if (opts.shift) {
                const x = Math.round(getPixel(ptX.x, ptX.y, perlin.imageData)[0] / 255 * 16 - 8)
                const y = Math.round(getPixel(ptY.x, ptY.y, perlin.imageData)[0] / 255 * 16 - 8)
                gsap.to(el, { x, y, force3D: true, duration: 0.1 });
            }
        }

        window.subscribeToTick(render);

        if (bubbles) {
            const animation = lottie.loadAnimation({
                container: bubbles,
                path: './lottie/bubbles/data.json',
                renderer: 'canvas',
                loop: true,
                autoplay: true,
            });
        }
    }

    const img = el.querySelector('img');
    if(img.complete && img.naturalHeight !== 0) {
        init();
    } else {
        img.addEventListener('load', init);
    }
}


export default Trash;