import throttle from '../utils/throttle';
const LIMIT = 8;

const RuggedNFT = el => {

  const img = el.querySelector('img');
  const span = el.querySelector('span');

  const handleMouseMove = e => {
    const center = span.getBoundingClientRect();
    const x = e.clientX - (center.left + center.width / 2);
    const y = e.clientY - (center.top + center.height / 2);
    gsap.to(img, {x, y});
  }

  const handleMouseOver = e => {
    gsap.fromTo(img, {opacity:0}, {opacity:1, onStart:() => {
      gsap.set(img, {display: 'block'});
    }});
    span.addEventListener('mousemove', handleMouseMove);
    el.style.zIndex = 20;

  };

  const handleMouseOut = e => {
    gsap.to(img, {
      duration: 0.15,
      opacity: 0, onComplete: () => {
        el.style.zIndex = '';
        gsap.set(img, { display: '' });
      }
    })
    span.removeEventListener('mousemove', handleMouseMove);
  };

  span.addEventListener('mouseover', handleMouseOver);
  span.addEventListener('mouseout', handleMouseOut);
}

export default RuggedNFT;