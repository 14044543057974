import react, { useEffect, useState, useRef } from 'react';
import gsap from 'gsap';
import ASDK from '../../utils/anvil-sdk.es'
import bem from '../../utils/bem.js'
import { policies, cardolphinPolicy, partnerProjects } from './policies';
import { getCookie, setCookie } from '../../utils/cookies';
// import data from './data.json';
const BLOCK_CLASS = 'feed-the-kraken';
const SECS = 10;

// console.log(policies.concat(cardolphinPolicy, partnerProjects))

const STATUS = {
  CONNECTING: 'connecting',
  CONNECTED: 'connected',
  FETCHING: 'fetching',
  FETCHED: 'fetched'
};

const STATE = {
  INIT: 'init',
  INVENTORY: 'inventory',
  TRANSACTION: 'transaction',
  ERROR: 'error',
  SUCCESS: 'success',
};

const DEV_WALLETS = [{
  name: 'nami',
  addr: 'addr1qy57vt9wyszpr47n56ujjpd580eyn56rakdyds9dx9jcn6xwuwr4hk8tyq3vvzr4js4e8de38t0z2z9q8xjcy7c356us0wrgzj'
}, {
  name: 'eternl',
  addr: 'addr1q9nqt608e0kjpvy65nuugfzweuvelmhnthjezzt2fcjmcwg4yu4cnwwtarqplu7c8ffsf2pr7c42k39l4wrkaarya5usjt6r75'
}];

const SELECTORS = {
  choose: bem(BLOCK_CLASS, 'choose'),
  cross: bem(BLOCK_CLASS, 'cross > div'),
  inventory: bem(BLOCK_CLASS, 'inventory'),
  rect: bem(BLOCK_CLASS, 'rect'),
  rectChildren: bem(BLOCK_CLASS, 'rect > *'),
  text: bem(BLOCK_CLASS, 'text'),
  uploadButton: bem(BLOCK_CLASS, 'upload-btn'),
  videoContainer: bem(BLOCK_CLASS, 'video-container'),
}

let pollingID;

const CrossWrapper = () => <div className={bem(BLOCK_CLASS, 'cross-wrapper')}>
  <i className={bem(BLOCK_CLASS, 'cross')}>
    <div><i></i><i></i></div>
  </i>
  <i className={bem(BLOCK_CLASS, 'cross')}>
    <div><i></i><i></i></div>
  </i>
  <i className={bem(BLOCK_CLASS, 'cross')}>
    <div><i></i><i></i></div>
  </i>
  <i className={bem(BLOCK_CLASS, 'cross')}>
    <div><i></i><i></i></div>
  </i>
</div>

const ConnectToWallet = props => {

  const {
    connectionStatus,
    inventoryStatus,
    wallets,
    connectWallet
  } = props;

  return <div className={bem(BLOCK_CLASS, 'text') + " col-6"}>
    <div>
    <div className={bem(BLOCK_CLASS, 'rect')}>
      <h3 className="h3">CONNECT YOUR WALLET TO FILL YOUR KRAKEN&nbsp;CAPSULE</h3>
      {!wallets && <div className="loader">Finding available wallets</div>}

      {connectionStatus && connectionStatus === STATUS.CONNECTING &&
        <div>
          <p>Connecting to your wallet.</p>
        </div>
      }

      {inventoryStatus && inventoryStatus === STATUS.FETCHING &&
        <div>
          <p>Grabbing rugged assets.</p>
        </div>
      }

      {wallets &&
        wallets.length > 0 &&
        connectionStatus !== STATUS.CONNECTING &&
        inventoryStatus !== STATUS.FETCHING &&
        <WalletList wallets={wallets} connectWallet={connectWallet} />
      }
    </div>
    <CrossWrapper />
    </div>
  </div>
}

const SuccessMessage = ({total, handleStartOver}) => {

  return <div className={bem(BLOCK_CLASS, 'text') + " col-5"}>
    <div>
    <div className={bem(BLOCK_CLASS, 'rect')}>
      <h3 className="h3 mb-4">You fed the Kraken {total} time{total > 1 && 's'}</h3>
      <p>Check the wallet you sent your rugged NFTs from on December 17th, to see if you’ve won any NFTs from our Treasure Chest.</p>
        <div className="d-flex pt-5">
        <button onClick={handleStartOver} className="btn py-2 me-3">Feed Again</button>
          <a target='_blank' href="https://twitter.com/intent/tweet?text=The%20Kraken%20ate%20my%20worthless%20rug%21%20now%20i%27m%20entered%20to%20win%20one%20of%20300%20%23CNFTs%20with%20real%20value%20from%20%40Cardolphins%20Treasure%20Chest.%20WOOP%21%20WOOP%21%0A%23Excitement%20%23RugSnacks%20%23Cardano%20%23TheKraken%20https://t.co/kx3EuvTJfN" className="btn py-2">Share</a>
        </div>
    </div>
    <CrossWrapper />
    </div>
  </div>
}

const ErrorMessage = props => {
// <div className={`col-5 text-center ${bem(BLOCK_CLASS, `message`)}`}>
  return <div className={bem(BLOCK_CLASS, 'text') + " col-5"}>
    <div>
      <div className={bem(BLOCK_CLASS, 'rect')}>
        <p>There seems to be an error. Your CNFTs are still safe in your wallet. Please try again later. If you continue having issues, please join our discord.</p>
      </div>
      <CrossWrapper />
    </div>
  </div>
}

const Instructions = ({hasCardolphin = false, hasPartnerProject = false}) => {
  return <div className={bem(BLOCK_CLASS, 'choose') + " col-5"}>
    <h3 className="h3">CHOOSE RUGGED NFTs<br/>TO FEED THE KRAKEN</h3>
    <h6 className="h6">WARNING: This transaction is non-reversible & submissions are permanent.</h6>
    <p>Increase your odds of winning by minting a Cardolphin <a href="/">here</a> on our site or buying one at <a href="">JPGStore</a>.</p>
    <h6 className="h6 red">ODDS BOOST {hasCardolphin || hasPartnerProject ? '' : 'NOT '}ACTIVATED</h6>
    <div className={bem(BLOCK_CLASS, 'boosts')}>
      {hasCardolphin &&
      <div className={bem(BLOCK_CLASS, 'boost')}>
        <h6 className={bem(BLOCK_CLASS, 'boost-amount') + ' h2'}>
          3x
        </h6>
        <h4 className={bem(BLOCK_CLASS, 'boost-type') + ' h6'}>
          Cardolphins Holder
        </h4>
      </div>
      }
      {!hasCardolphin && hasPartnerProject &&
      <div className={bem(BLOCK_CLASS, 'boost')}>
        <h2 className={bem(BLOCK_CLASS, 'boost-amount') + ' h2'}>
          2x
        </h2>
        <h6 className={bem(BLOCK_CLASS, 'boost-type') + ' h6'}>
          Partner Project Holder
        </h6>
      </div>
      }
    </div>
  </div>
}

const Inventory = ({ inventory = [], toggleSelected = () => { } }) => {
  return <div className={bem(BLOCK_CLASS, 'inventory') + " col-7"}>
    <div className={bem(BLOCK_CLASS, 'rect')}>
      {inventory.length === 0 &&
      <div>
          <h4 className="h4">RUG SNACKS ERROR:</h4>
        <p>No rugged CNFTs found in your wallet.</p>
      </div>
      }
      <div className={bem(BLOCK_CLASS, 'inventory-content')}>
        {inventory.map(item => {
          let image = Array.isArray(item.metadata.image) ? item.metadata.image.join('') : item.metadata.image;
          if (image.indexOf('http') === -1) {
            image = `https://nftstorage.link/ipfs/${image.replace('ipfs://', '')}`;
          }
          return <div
            data-id={item.id}
            key={item.id}
            onClick={toggleSelected}>
            <img src={image} />
            {/* <img src={item.url} /> */}
          </div>
        })}
      </div>
    </div>
    <CrossWrapper />
  </div>
}

const Capsule = ({ capsule, type, className = 'offset-1 col-5' }) => {

  let videoSrc;
  switch (type) {
    case 'success':
      videoSrc = 'success'
      break;
    case 'loading':
      videoSrc = 'toxic'
      break;
    default:
      videoSrc = 'idle'
  }

  return <div ref={capsule} className={bem(BLOCK_CLASS, 'capsule') + " " + className}>
    <div className={bem(BLOCK_CLASS, 'capsule-wrapper')}>
      <video autoPlay loop muted>
        <source src={`/kraken/${videoSrc}.mp4`} type='video/mp4; codecs="hvc1"' />
        <source src={`/kraken/${videoSrc}.webm`} type="video/webm" />
      </video>
    </div>
  </div>
}

const WalletList = ({ wallets, connectWallet }) => {
  const icons = {
    'eternl': 'wallet-eternl.png',
    'flint': 'wallet-flint.svg',
    'gerowallet': 'wallet-gero.svg',
    'nami': 'wallet-nami.png',
    'typhoncip30': 'wallet-typhon.svg',
  }
  return <ul className={bem(BLOCK_CLASS, 'wallet-list')}>
    {wallets.map(wallet => (<li key={wallet} className={bem(BLOCK_CLASS, 'wallet-list-item')}>
      <a href="#" onClick={() => connectWallet(wallet)}>
        <img src={`/kraken/${icons[wallet]}`} />
        <h6 className="h6">{wallet}</h6>
      </a>
    </li>))}
  </ul>
}

const findPartnerProject = item => {
  const { policyId } = item;
  const index = partnerProjects.indexOf(policyId);
  // console.log('findPartnerProject policy id:', policyId)
  console.log('findPartnerProject:', index)
  if (index > -1) return item;
}

const findCardolphins = item => {
  const { policyId } = item;
  const index = cardolphinPolicy.indexOf(policyId);
  console.log('findCardolphins:', index)
  if (index > -1) return item;
}

const playInVideo = video => {
  gsap.fromTo(video, {
    clipPath: 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)'
  }, {
    clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)'
  });
}

const playOutVideo = video => {
  gsap.fromTo(video, {
    clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)'
  }, {
    clipPath: 'polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)',
    onStart:() => {
      video.nextElementSibling.style.display = 'block';
    }
  });
}

const playInRect = (el, tl) => {
  const duration = 0.2;
  const ease = 'Circ.easeInOut';
  tl.fromTo(el.current.querySelector(`.${SELECTORS.rect}`), {
    opacity: 0,
    scale: 0
  }, {
    ease,
    scale: 1,
    duration: 0.6,
    opacity: 1,
    z: 0
  }, 'out+=0');

  [...el.current.querySelectorAll(`.${SELECTORS.cross}`)].forEach((cross, index) => {
    const rect = cross.parentElement.getBoundingClientRect();
    tl.from(cross, {
      x: rect.width * ((index + 1) % 2 === 0 ? -1 : 1),
      y: rect.height * (index > 1 ? -1 : 1),
      opacity: 0,
      duration: 0.6,
      ease,
    }, 'out+=0');
  });


  tl.from(el.current.querySelectorAll(`.${SELECTORS.rectChildren}`), {
    duration,
    ease,
    opacity: 0
  }, 'out+=0.4');
}

const playOutRect = (el, tl) => {
  const duration = 0.2;
  const ease = 'Circ.easeInOut';
  const children = el.current.querySelectorAll(`.${SELECTORS.rectChildren}`);
  const text = el.current.querySelector(`.${SELECTORS.text}`)

  tl.to(el.current.querySelector(`.${SELECTORS.rect}`), {
    ease,
    scale: 0,
    duration: 0.6,
    opacity: 0,
    z: -100
  }, 'out+=0');


  [...el.current.querySelectorAll(`.${SELECTORS.cross}`)].forEach((cross, index) => {
    const rect = cross.parentElement.getBoundingClientRect();
    tl.to(cross, {
      x: rect.width * ((index + 1) % 2 === 0 ? -1 : 1),
      y: rect.height * (index > 1 ? -1 : 1),
      opacity: 0,
      duration: 0.6,
      ease,
    }, 'out+=0');
  });

  if (children) {
    tl.to(children, {
      duration,
      ease,
      opacity: 0
    }, 'out+=0');
  }

  if (text) {
    tl.to(text, {
      duration,
      ease,
      z: -200
    }, 'out+=0');
  }
}

const playInCapsule = (capsule, tl) => {
  const duration = 0.6;
  const ease = 'Circ.easeOut';
  tl.from(capsule.current, {
    duration,
    ease,
    force3D: true,
    opacity: 0,
    z: 300,
  }, 'out+=0')
}

const playOutCapsule = (capsule, tl) => {
  const duration = 0.6;
  const ease = 'Circ.easeInOut';
  tl.to(capsule.current, {
    duration,
    ease,
    force3D: true,
    opacity: 0,
    z: -300,
  }, 'out+=0')
}

const FeedKraken = ({ onStateChange }) => {

  // let tmpInventory = [];

  // for (let i = 0; i < 30; i++) {
  //   const policyId =
  //     // i === 2 ? 'b5fb333ad44e4853484cef178f543c35564490534aafcdf919fa4687'
  //       i === 5 ? 'd6fe6efa7788cb70e57a91891605e3694352cabb4837e870610300e9'
  //         : '';

  //   tmpInventory.push({
  //     policyId,
  //     id: i,
  //     url: `https://picsum.photos/seed/${Math.round((Math.random() * 10000))}/200`
  //   });
  // }

  // console.log(tmpInventory)

  const [connectionStatus, setConnectionStatus] = useState();
  const [inventoryStatus, setInventoryStatus] = useState();
  const [wallets, setWallets] = useState(undefined);
  const [wallet, setWallet] = useState(undefined);
  const [hasPartnerProject, setPartnerProject] = useState(false);
  const [hasCardolphin, setCardolphin] = useState(false);
  const [inventory, setInventory] = useState();
  const [selected, setSelected] = useState([]);
  const [state, setState] = useState(STATE.INIT);
  const [status, setStatus] = useState(JSON.parse(getCookie('nft-status') || "{}"));
  const capsule = useRef(null);
  const el = useRef(null);
  const successTl = useRef(gsap.timeline({ paused: true, delay: 0.5 }));

  useEffect(() => {
    const getWallets = async () => {
      if (!wallets) {
        setWallets(await ASDK.getAvailableExtensions());
      }
    }
    getWallets();
  }, [wallets]);

  useEffect(() => {
    if (status.response) {
      if (state !== STATE.TRANSACTION) {
        setState(STATE.TRANSACTION)
      }
      if (pollingID) clearInterval(pollingID);
      pollingID = setInterval(() => {
        poll(status.response);
      }, SECS * 1000);
      poll(status.response);
    } else if (pollingID) {
      clearInterval(pollingID);
    }
  }, [status]);

  useEffect(() => {
    if(state === STATE.SUCCESS && el.current) {
      const video = el.current.querySelector(`.${SELECTORS.videoContainer}`)
      playInVideo(video);
    }
    onStateChange(state);

    const tl = gsap.timeline();
    const duration = 0.2;
    const ease = 'Circ.easeInOut';

    tl.add('out', 0);

    if(state === STATE.INIT) {
      playInRect(el, tl);
      playInCapsule(capsule, tl);

    }
    if(state === STATE.INVENTORY) {
      playInRect(el, tl);

      tl.fromTo(el.current.querySelectorAll(`.${SELECTORS.choose} > *`), {
        opacity: 0,
        z: 100
      }, {
        ease: 'Circ.easeOut',
        force3D: true,
        duration: 0.6,
        opacity: 1,
        stagger: 0.09,
        z: 0
      }, 'out+=0');

      tl.from(el.current.querySelector(`.${SELECTORS.uploadButton}`), {
        opacity: 0,
        ease,
        duration: 0.6,
      }, 'out+=0');
    }


    if(state === STATE.TRANSACTION) {
      playInCapsule(capsule, tl);
    }
    if(state === STATE.SUCCESS) {

      playInCapsule(capsule, successTl.current);
      playInRect(el, successTl.current);
    }

  }, [state]);

  const reset = () => {
    setWallet(undefined);
    setInventory(undefined);
    setSelected([]);
    setStatus({})
    setState(STATE.INIT);
  }

  const updateState = newState => {
    // playOut current view
    const tl = gsap.timeline({onComplete:() => {
      setState(newState);
    }});

    const duration = 0.2;
    const ease = 'Circ.easeInOut';

    tl.add('out', 0);

    if(state === STATE.INIT) {

      playOutRect(el, tl);
      playOutCapsule(capsule, tl);
    }

    if (state === STATE.INVENTORY) {
      playOutRect(el, tl);
      tl.to(el.current.querySelectorAll(`.${SELECTORS.choose} > *`), {
        ease: 'Circ.easeIn',
        force3D: true,
        duration: 0.6,
        opacity: 0,
        stagger: {
          from: 'end',
          each: 0.04
        },
        z: -100
      }, 'out+=0');

      tl.to(el.current.querySelector(`.${SELECTORS.uploadButton}`), {
        duration,
        ease,
        opacity:0
      }, 'out+=0');
    }

    if (state === STATE.TRANSACTION) {
      playOutCapsule(capsule, tl);
    }


  }

  const poll = async response => {
    const currentStatus = await ASDK.getTxStatus(response);
    console.log(currentStatus);
    if (currentStatus === "confirmed") {
      setStatus({ complete: true });
      setCookie('nft-status', "", -1);
      updateState(STATE.SUCCESS);
    }
  }

  const connectWallet = async wallet => {
    setConnectionStatus(STATUS.CONNECTING);
    const connected = await ASDK.connect(wallet);
    if (connected) {
      setWallet(wallet);
      setConnectionStatus(STATUS.CONNECTED);
      setInventoryStatus(STATUS.FETCHING);
      let cardolphins, partners, inventory;

      cardolphins = await ASDK.getAssets(cardolphinPolicy);
      if(cardolphins.length > 0) setCardolphin(true);
      partners = await ASDK.getAssets(partnerProjects);
      if (partners.length > 0) setPartnerProject(true);
      inventory = await ASDK.getAssets(policies);

      setInventory(inventory);
      setInventoryStatus(STATUS.FETCHED);
      updateState(STATE.INVENTORY);
    }
  }

  const onUploadClick = async () => {
    // get all units
    const ids = selected.map(el => parseFloat(el.dataset.id));
    const units = ids.map(id => {
      return inventory.find(item => item.id === id)?.unit;
    });
    const addr = 'addr1qy2zkvrz7w672e882aee5v3ygnglyluhznxjnz44saevdq7fvluducf4pd7qjkn2r4jkkge56dvxxmnqe8tm66hegwvqfrjy8r';
    // const otherWallet = DEV_WALLETS.find(obj => obj.name !== wallet);
    // if (otherWallet) {
      updateState(STATE.TRANSACTION);
      // const addr = otherWallet.addr
      let response;
      try {
        console.log(addr, units)
        response = await ASDK.sendNfts(addr, units);
      } catch(e) {
        console.log(e);
      }

      if(!response) {
        updateState(STATE.ERROR);
        return;
      };

      const statusObj = {
        addr,
        units,
        response
      };
      setCookie('nft-status', JSON.stringify(statusObj), 1);
      setStatus(statusObj);
    // }
  }

  const toggleSelected = e => {
    const target = e.currentTarget;
    target.classList.toggle('selected');
    setSelected([...target.parentElement.querySelectorAll('.selected')]);
  }

  // const updateState = state => {}

  // console.log(inventory)

  // const getState = () => {
  //   return STATE.SUCCESS
  //   if (status && status.response) return STATE.TRANSACTION;
  //   if (status && status.complete) return STATE.SUCCESS;
  //   if (!wallets) return STATE.INIT;
  //   if (wallets.length > 0 && !inventory) return STATE.INIT;
  //   if (inventory) return STATE.INVENTORY;
  //   return STATE.INIT;
  // }

  // const state = getState();
  // if (state === STATE.SUCCESS) console.log(el.current)
  // onStateChange(state);

  return <div ref={el}>

    {/* Debug Tools */}
    {/* <div className={bem(BLOCK_CLASS, 'debug')}>
      {Object.keys(STATE).map(name => <button className='btn' key={name} onClick={() => {
        updateState(STATE[name])
      }}>{STATE[name]}</button>) }
    </div> */}

    {state === STATE.INIT &&
      <div className="container">
        <div className="row">
          <Capsule capsule={capsule} />
          <ConnectToWallet
            wallets={wallets}
            connectionStatus={connectionStatus}
            inventoryStatus={inventoryStatus}
            connectWallet={connectWallet} />
        </div>
      </div>
    }

    {state === STATE.INVENTORY &&
      <div className="container">
        <div className="row">
          <Instructions hasCardolphin={hasCardolphin} hasPartnerProject={hasPartnerProject}  />
          <Inventory inventory={inventory} toggleSelected={toggleSelected} />
        </div>
        { inventory && inventory.length > 0 &&
        <div key="upload" className='row py-4'>
          <div className='col-4'></div>
          <div className='offset-1 col-7'>
            <button onClick={onUploadClick} className={`${selected.length > 0 ? '' : 'disabled'} btn kraken-hero__btn ${bem(BLOCK_CLASS, 'upload-btn')}`}>Upload NFTs</button>
          </div>
        </div>
        }
      </div>
    }

    {state === STATE.TRANSACTION &&
      <div className="container">
        <div className='row'>
          <Capsule capsule={capsule} type='loading' className='offset-1 col-6' />
          <div className={`col-5 text-center ${bem(BLOCK_CLASS, `message`)}`}>
            <div>
              <div className={bem(BLOCK_CLASS, 'loading')}>
                <h2 className='h2'>LOADING</h2>
              </div>
              <p className='mt-4'>May take a few minutes.<br/>Do not refresh.</p>
            </div>
          </div>
        </div>
      </div>
    }

    {state === STATE.ERROR &&
      <div className="container">
        <div className='row'>
          <Capsule capsule={capsule} type='loading' className='offset-1 col-6' />
          <ErrorMessage />
        </div>
      </div>
    }

    {state === STATE.SUCCESS &&
      [<div key='video-container' className={bem(BLOCK_CLASS, 'video-container')}>
        <video
          onEnded={() => {
            const video = el.current.querySelector(`.${SELECTORS.videoContainer}`)
            successTl.current.play();
            playOutVideo(video);
           }}
          autoPlay
          muted
          src="/kraken/KrakenGrab_Render4.mp4"></video>
        </div>,
      <div key='container' className="container">
        <div className='row'>
          <Capsule capsule={capsule} type='success' className='offset-1 col-6' />
          <SuccessMessage total={selected.length} handleStartOver={reset} />
        </div>
      </div>]
    }

  </div>
}


export default FeedKraken;