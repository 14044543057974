import lottie from 'lottie-web';
import { randomRange } from '../utils/randomRange';
import chainImg from '../../assets/images/hero_dolphin/chain.png';

const HeroDolphin = el => {
    const container = el.querySelector('.hero__dolphin-container');
    const irisImage = el.querySelector('.hero__dolphin-iris img');
    const eyelid = el.querySelector('.hero__dolphin-eyelid');
    const chain = el.querySelector('.hero__dolphin-chain');
    const shark = el.querySelector('.hero__dolphin-shark');
    const sharkImg = el.querySelector('.hero__dolphin-shark-tmp');
    const flagEl = el.querySelector('.hero__dolphin-flag');
    const flagImg = el.querySelector('.hero__dolphin-flag img');
    const flagGradientImg = el.querySelector('.hero__dolphin-flag img:last-child');
    const sonorRings = el.querySelectorAll('.hero__dolphin-sonor .rings i');
    const sonorLight = el.querySelectorAll('.hero__dolphin-sonor .rings .light');
    const mousePos = { x: window.innerWidth / 2, y: window.innerHeight / 2 }
    let rect = el.getBoundingClientRect();
    let autoPlayEye = true;

    const perlin = window.perlin;

    /** Eye functions */

    const moveIris = () => {
        const px = mousePos.x / (window.innerWidth / 2) - 1;
        const py = mousePos.y / (window.innerHeight / 2) - 1;
        const xAmount = rect.width < 600 ? (px * 4 - 2) : (px * 10 - 5);
        const yAmount = rect.width < 600 ? (px * 3 + 2) : (px * 5 + 5);
        gsap.set(irisImage, {
            x: Math.round(xAmount * 100) / 100,
            y: Math.round(yAmount * 100) / 100,
            force3D: true
        })

    }

    const animateEye = () => {
        const px = Math.random() * window.innerWidth;
        const py = Math.random() * window.innerHeight;
        gsap.to(mousePos, {
            duration: 1,
            ease: Cubic.easeInOut,
            x: px,
            y: py,
            onComplete: () => {
                if (autoPlayEye) mousemoveTimeout = setTimeout(animateEye, randomRange(2, 5) * 1000);
            }
        })
    }

    const blink = () => {
        const rect = container.getBoundingClientRect()
        gsap.fromTo(eyelid, {
            backgroundPosition: `0px 0px`,
        }, {
            backgroundPosition: `${(rect.width) * -5}px 0px`,
            ease: SteppedEase.config(5),
            yoyo: true,
            repeat: 1,
            duration: 0.15
        });
        setTimeout(blink, randomRange(1, 5) * 1000);
    }

    setTimeout(blink, 2000);

    const sonor = () => {
        const tl = gsap.timeline({ repeat: -1, repeatDelay: 3 });
        const stagger = 0.04;
        const x = 800;
        const y = x / 100 * 40;
        const duration = 1.2;
        const scale = 12;
        const ease = Linear.easeNone;

        tl.add("in")
        tl.fromTo(sonorLight, { opacity: 0 }, { duration: 0.08 * duration, opacity: 1, ease }, 'in+=0')
        tl.to(sonorLight, { duration: duration * 0.8, opacity: 0, }, `in+=${0.08 * duration}`)
        tl.to(sonorRings, { duration, x, y, scale, stagger, ease }, 'in+=0')
        tl.to(sonorRings, { duration: 0.5, opacity: 0, stagger, ease }, `in+=${duration - 0.5}`)

    }
    sonor();

    /** Flag animation */
    const waveFlag = () => {
        const app = new PIXI.Application({
            width: 936,
            height: 1041,
            backgroundAlpha: 0
        });

        flagEl.appendChild(app.view);

        const container = new PIXI.Container();
        app.stage.addChild(container);

        const flagSpr = PIXI.Sprite.from(flagImg);
        container.addChild(flagSpr);
        flagImg.style.display = 'none';

        let renderer = PIXI.autoDetectRenderer();
        const renderTexture = PIXI.RenderTexture.create({ width: 936, height: 1041 });

        const perlinSpr = PIXI.Sprite.from(perlin.canvas);
        perlinSpr.scale.y = rect.height / perlin.size;
        perlinSpr.scale.x = rect.width / perlin.size;
        container.addChild(perlinSpr);

        const flagGradientSpr = PIXI.Sprite.from(flagGradientImg);
        container.addChild(flagGradientSpr);
        flagGradientImg.style.display = 'none';

        const displacementFilter = new PIXI.filters.DisplacementFilter(container);
        displacementFilter.scale.x = 20;
        displacementFilter.scale.y = 15;

        flagSpr.filters = [displacementFilter];

        // renderer.render(perlinSpr, { renderTexture });
        // renderer.render(flagGradientSpr, { renderTexture });
        // container.addChild(PIXI.Sprite.from(renderTexture));

        const update = () => {
            perlinSpr.texture.baseTexture.resource.source.getContext('2d').putImageData(perlin.imageData, 0, 0)
            perlinSpr.texture.baseTexture.resource.update();
            perlinSpr.texture.update();
            // perlinSpr.texture.baseTexture.resource.source.getContext('2d').putImageData(perlin.imageData, 0, 0)
            // perlinSpr.texture.update();
        }

        window.subscribeToTick(update);
    }

    // waveFlag();


    /** Chain functions */


    const glimmerChain = () => {

        const app = new PIXI.Application({
            width: 936,
            height: 1041,
            backgroundAlpha: 0
        });

        chain.appendChild(app.view);

        const container = new PIXI.Container();
        app.stage.addChild(container);

        const chainSpr = PIXI.Sprite.from(chainImg);
        container.addChild(chainSpr);

        const shimmerSpr = PIXI.Sprite.from(perlin.canvas);
        shimmerSpr.scale.y = rect.height / perlin.size;
        shimmerSpr.scale.x = rect.width / perlin.size;
        shimmerSpr.alpha = 0;
        shimmerSpr.blendMode = PIXI.BLEND_MODES.ADD;

        const tl = new gsap.timeline({ repeat: -1, repeatDelay: 4 });
        tl.fromTo(shimmerSpr, { alpha: 0 }, { alpha: 0.2, duration: 0.8, ease: Cubic.easeInOut })
        tl.to(shimmerSpr, { alpha: 0, duration: 0.6, ease: Cubic.easeOut })

        container.addChild(shimmerSpr);

        const updateShimmer = () => {
            if (shimmerSpr) {
                shimmerSpr.texture.baseTexture.resource.source.getContext('2d').putImageData(perlin.imageData, 0, 0)
                shimmerSpr.texture.update();
            }
        }

        window.subscribeToTick(updateShimmer);
    }

    window.subscribeToTick(moveIris);

    glimmerChain();

    /** Shark animation */

    const animation = lottie.loadAnimation({
        container: shark,
        path: './lottie/shark/data.json',
        renderer: 'canvas',
        loop: true,
        autoplay: true,

    });

    animation.setSpeed(1);
    animation.addEventListener('data_ready', e => {
        sharkImg.style.display = 'none';
    });

    /** Flag animation */


    let mousemoveTimeout = null

    window.addEventListener('mousemove', e => {
        mousePos.x = e.clientX;
        mousePos.y = e.clientY;
        autoPlayEye = false;
        clearTimeout(mousemoveTimeout);
        mousemoveTimeout = setTimeout(() => {
            autoPlayEye = true;
            animateEye();
        }, 3000);
    });

    window.addEventListener('resize', e => {
        rect = el.getBoundingClientRect();
    });

    animateEye();

}

export default HeroDolphin;