import Carousel from "./components/carousel";
import FeedKrakenWrapper from "./components/feed-kraken";
import Float from "./components/float";
import HeroDolphin from "./components/hero-dolphin";
import InputNumber from "./components/input-number";
import MintModal from "./components/mint-modal";
import Nav from "./components/nav";
import NFTList from "./components/nft-list";
import Parallax from "./components/parallax";
import Reveal from "./components/reveal";
import RuggedNFT from "./components/rugged-nft";
import RuggedNftList from "./components/rugged-nft-list";
import SideNav from "./components/sidenav";
import Trash from "./components/trash";
import Video from "./components/video";

import PerlinNoise from "./utils/perlinNoise";
window.perlin = PerlinNoise();

const Components = {
    carousel: Carousel,
    float: Float,
    'feed-kraken': FeedKrakenWrapper,
    'hero-dolphin': HeroDolphin,
    'input-number': InputNumber,
    'mint-modal': MintModal,
    'nft-list': NFTList,
    parallax: Parallax,
    nav: Nav,
    reveal: Reveal,
    'rugged-nft': RuggedNFT,
    'rugged-nft-list': RuggedNftList,
    sidenav: SideNav,
    trash: Trash,
    video: Video
};

const subscriptions = [];
window.subscribeToTick = fn => {
    if (subscriptions.indexOf(fn) === -1) {
        subscriptions.push(fn);
    }
}

const tick = () => {
    subscriptions.forEach(fn => fn());
    requestAnimationFrame(tick)
}

window.addEventListener('load', () => {
    [...document.querySelectorAll('[data-component]')].forEach(el => {
        const name = el.dataset.component;
        if (Components[name]) {
            Components[name](el);
        }
    });
})

subscribeToTick(window.perlin.render);

tick();