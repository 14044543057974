import throttle from "../utils/throttle";

const Reveal = el => {

    let isShown = true;
    const y = '+=30';
    const duration = 0.7;
    const ease = Cubic.easeOut

    const handleScroll = () => {
        const { top } = el.getBoundingClientRect();
        if(top > window.innerHeight && isShown) {
            isShown = false;
            gsap.set(el, { opacity: 0, y});
        } else if(top < window.innerHeight * 0.8 && !isShown) {
            isShown = true;
            gsap.fromTo(el, {opacity:0, y}, {force3D:true, opacity: 1, y:0, duration, ease })
        }

    }
    window.addEventListener('scroll', throttle(handleScroll, 50), false);
    handleScroll();

}

export default Reveal;