import { getCoords } from "../utils/coordinates";
import { debounce } from "../utils/debouce";


const Parallax = el => {


    const scaleY = el.dataset.offsetY ? parseFloat(el.dataset.offsetY) : 0;
    const trigger = el.dataset.trigger ? parseFloat(el.dataset.trigger) : 0.5;
    let y, oy = 0, vh;

    const calculateCoords = () => {
        vh = window.innerHeight;
        const transform = el.style.transform;
        el.style.transform = '';
        const coords = getCoords(el);
        const rect = el.getBoundingClientRect();
        oy = (coords.top + rect.height / 2) - (vh * trigger);
        el.style.transform = transform;
    }

    const tick = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        const p = (scrollTop - oy) / (2 * vh + oy);

        if (p >= 0 && p <= 1) {
            y = Math.round((-p * vh * scaleY));
            gsap.to(el, { duration: 0.2, force3D: true, y})
        } else if (p < 0) {
            gsap.to(el, { duration: 0.2, force3D: true, y: 0})
        }
    }

    const images = el.tagName === 'IMG' ? [el] : [...el.querySelectorAll('img')];
    let toLoad = images.length;

    const handleLoad = () => {
        toLoad--;
        if (toLoad <= 0) {
            calculateCoords();
            window.subscribeToTick(tick);
        }
    }

    images.forEach(img => {
        if (img.complete && img.naturalHeight !== 0) toLoad--;
        else img.addEventListener('load', handleLoad);
        if (toLoad === 0) handleLoad();
    });

    if (images.length === 0) handleLoad();

    window.addEventListener('resize', debounce(calculateCoords, 100));

}

export default Parallax;