import throttle from '../utils/throttle';

const LIMIT = 8;
// .treasure-chest__list

const RuggedNftList = el => {
  const list = el.querySelector('.treasure-chest__list');
  const items = [...el.querySelectorAll('.treasure-chest__list-item')];

  const handleResize = () => {
    if (window.innerWidth >= 576) {
      const listBox = list.getClientBoundingBox();
      const itemBox = items[LIMIT - 1].getClientBoundingBox();
      const maxHeight = (itemBox.itemBox.height + itemBox.y) - listBox.y
      list.style.maxHeight = `${maxHeight}px`;
    } else {
      // show all
      list.style.maxHeight = '';
    }
  }

  window.addEventListener('resize', throttle(handleResize, 100));
  handleResize();
}

export default RuggedNftList;