
const MintApi = {
    url : {
        development: 'http://localhost:9999',
        production: './api/'
    },

    getNFTCount: () => {

        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            const url = MintApi.url[process.env.NODE_ENV] || MintApi.url.production;

            fetch(`${url}/get-mint-count.php`, requestOptions)
                .then(response => response.json())
                .then(resolve)
                .catch(reject);
        });
    }
}

const startTimer = (duration, display) => {
    var timer = duration, minutes, seconds;
    setInterval(function () {
        minutes = parseInt(timer / 60, 10)
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        display.value = `${minutes} minutes ${seconds} seconds`;

        if (--timer < 0) {
            timer = duration;
            alert("Transaction Expired");
            window.location.reload(true);
        }
    }, 1000);
}


const CSS_SELECTORS = {
    NFT_REMAINING: '#nft_remaining',
    NFT_REMAINING_TOTAL: '#nft_remaining_total',
    BUY_NOW_BTN: '#buyNow',
    COUNT: '.count',
    TOTAL: '.total',
    MINT_INFO: '#mint-info',
    MINT_INFO_COMPLETE: '#mint-info-complete',
    INPUT_NUMBER: '.input__number input',
};

const MintModal = el => {

    const buyNow = el.querySelector(CSS_SELECTORS.BUY_NOW_BTN);
    const countEl = el.querySelector(CSS_SELECTORS.COUNT);
    const totalEl = el.querySelector(CSS_SELECTORS.TOTAL);
    const mintInfoEl = el.querySelector(CSS_SELECTORS.MINT_INFO);
    const mintInfoCompleteEl = el.querySelector(CSS_SELECTORS.MINT_INFO_COMPLETE);
    const numberEl = el.querySelector(CSS_SELECTORS.INPUT_NUMBER);

    const openPaymentWindow = id => {
        var paymentUrl = "https://payment.nft-maker.io/?p=f64a256ae5cf49d6a998d9c180795e63&c=" + id; // Specify the popup width and height

        var popupWidth = 500;
        var popupHeight = 700; // Calculate the center of the screen

        var left = window.top.outerWidth / 2 + window.top.screenX - popupWidth / 2;
        var top = window.top.outerHeight / 2 + window.top.screenY - popupHeight / 2;
        var popup = window.open(paymentUrl, "NFT-MAKER PRO Payment Gateway", "popup=1, location=1, width=".concat(popupWidth, ", height=").concat(popupHeight, ", left=").concat(left, ", top=").concat(top)); // Show dim background

        document.body.style = "background: rgba(0, 0, 0, 0.5)"; // Continuously check whether the popup has been closed

        var backgroundCheck = setInterval(function () {
            if (popup.closed) {
                clearInterval(backgroundCheck);
                console.log("Popup closed"); // Remove dim background
                document.body.style = "";
            }
        }, 1000);
    }


    const updateNFTCount = () => {
        MintApi.getNFTCount().then(data => {
            const {
                nftcount,
                nfttotal,
                free
            } = data;
            countEl.innerHTML = nftcount;
            totalEl.innerHTML = nfttotal;

            if (free === 0) {
                mintInfoEl.style.display = "none";
                mintInfoCompleteEl.style.display = "block";
            }
        })
    };

    const reset = () => {
        [...el.querySelectorAll('.modal-body')].forEach(el => {
            el.classList.remove('hide');
            el.classList.remove('show');
        });
    };

    document.addEventListener("DOMContentLoaded", updateNFTCount);
    el.addEventListener('show.bs.modal', updateNFTCount);
    el.addEventListener('hidden.bs.modal', reset);

    buyNow.addEventListener('click', () => {
        const value = numberEl.value;
        openPaymentWindow(value);
    })


}

export default MintModal;