import * as ReactDOMClient from 'react-dom/client';
import FeedKraken from './FeedKraken';
import ASDK from '../utils/anvil-sdk.es'

const FeedKrakenWrapper = async el => {

  await ASDK.setApiKey('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwcm9qZWN0SWQiOjg3fQ.AAgr7O5l1TBawhSfpWm81cFShOtpUufY47IBiisnrcA');

  let currentState;

  const handleStateChange = state => {
    if (currentState) el.classList.remove(currentState);
    el.classList.add(state);
    currentState = state;
  }

  const root = ReactDOMClient.createRoot(el);
  root.render(<FeedKraken onStateChange={handleStateChange} />);

  window.feedKraken = async () => {
    el.classList.add('feed-the-kraken--open');
    document.body.classList.add('fixed');
  }

  el.addEventListener('click', e => {
    if (e.target === el) {
      el.classList.remove('feed-the-kraken--open');
      document.body.classList.remove('fixed');
    }
  })
}

export default FeedKrakenWrapper;