import { getPixel } from "../utils/perlinNoise";

const Float = el => {

    const opts = {
        shift: true
    }

    const perlin = window.perlin;
    const ptX = {
        x: Math.floor(Math.random() * perlin.canvas.width),
        y: Math.floor(Math.random() * perlin.canvas.height),
    }

    const ptY = {
        x: Math.floor(Math.random() * perlin.canvas.width),
        y: Math.floor(Math.random() * perlin.canvas.height),
    }
    const render = () => {
        if (opts.shift) {
            const x = Math.round(getPixel(ptX.x, ptX.y, perlin.imageData)[0] / 255 * 6 - 3)
            const y = Math.round(getPixel(ptY.x, ptY.y, perlin.imageData)[0] / 255 * 10 - 5)
            gsap.to(el, { x, y, force3D: true, duration: 0.3 });
        }
    }

    window.subscribeToTick(render);
}

export default Float;