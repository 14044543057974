import SimplexNoise from 'simplex-noise';

export const getPixel = (x, y, imageData) => {
    const index = (x + y * imageData.width) * 4;
    return [
        imageData.data[index + 0],
        imageData.data[index + 1],
        imageData.data[index + 2],
        imageData.data[index + 3]
    ]
}

const PerlinNoise = (canvas, opts = {
    running: true,
    size: 150,
    canvasScale: 2,
    xyScalar: 0.01,
    zScalar: 0.003,
    wScalar: 0.005,
    noiseOffset: 0.001
}) => {
    if(!canvas) {
        canvas = document.createElement('canvas');
    }

    // console.log(canvas)

    const simplex = new SimplexNoise(),
        canvasScale = 3.0,
        ctx = canvas.getContext('2d');

        let imageData,
            frameCount = 1;

    canvas.width = canvas.height = opts.size;
    canvas.style.width = `${opts.size * canvasScale}px`;
    canvas.style.height = `${opts.size * canvasScale}px`;

    // canvas.style.position = 'absolute';
    // canvas.style.top = 0;
    // document.body.appendChild(canvas);

    imageData = ctx.createImageData(canvas.width, canvas.height);

    const setPixel = (x, y, r, g, b, a) => {
        const index = (x + y * imageData.width) * 4;
        imageData.data[index + 0] = r;
        imageData.data[index + 1] = g;
        imageData.data[index + 2] = b;
        imageData.data[index + 3] = a;
    }

    return {
        canvas,
        ctx,
        imageData,
        render: () => {
            if (!opts.running) return;
            let x = 0,
                y = 0,
                c = 0,
                noiseVal = 0;
            // const noiseFn = ;
            for (x = 0; x < canvas.width; x++) {
                for (y = 0; y < canvas.height; y++) {
                    noiseVal = simplex.noise3D(
                        x * opts.xyScalar + opts.noiseOffset,
                        y * opts.xyScalar + opts.noiseOffset,
                        frameCount * opts.zScalar
                    );
                    c = Math.floor(noiseVal * 127 + 128);
                    setPixel(x, y, c, c, c, 255);
                }
            }
            frameCount++
            ctx.putImageData(imageData, 0, 0);
            opts.noiseOffset += opts.wScalar;
        },
        ...opts
    }

}

export default PerlinNoise;