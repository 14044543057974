const Nav = el => {
  const anchors = [...el.querySelectorAll('a')];
  const current = location.href.search('index.html') !== -1
    ? location.href.replace('index.html', '')
    : location.href;
  anchors.forEach(anchor => {
    // console.log(anchor.href === current)
    if (anchor.href === current) {
      anchor.classList.add('active');
    }
  });
};

export default Nav;